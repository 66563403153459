@import "../../styles/_variables";


.pageTitle {
    display: flex;
    margin-top: -2.5rem;
    font-size: 2rem;
    font-weight: 600;
    color: $orange100;
}

.center {
    justify-content: center;
}