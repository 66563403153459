@import "../../styles/_variables";

.backButton {
  font-size: 14px !important;
  line-height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.backIcon {
  margin-left: 5px;
}

.backLabel {
  margin-right: 5px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.table {
  display: block;
  height: 80vh;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  text-align: center;
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableRow {
  height: 50px;
}

.alignLeft {
  text-align: left;
}

.flagsRow {
  padding-left: 20px;
  padding-right: 10px;
}

.flag {
  max-width: 24px;
  cursor: help;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}

.disableFlag {
  filter: grayscale(1);
  cursor: default;
  opacity: 0.7;
}

.actionsContainer {
  text-align: center;
}

.questionRow {
  color: $gray400;
  text-align: left;
  padding-left: 20px;
  padding-right: 10px;
}

.unarchiveQuestionButton,
.moreActionsButton,
.deleteQuestionButton {
  cursor: pointer;
}

.unarchiveQuestionButton {
  filter: $green500SVG;
}

.moreActionsButton {
  color: $cyan500;
}

.deleteQuestionButton {
  // color: $red800;
  display: none !important;
  color: transparent;
}

.questionTitle {
  font-weight: 600;
  padding-left: 30px;
}

.moreActionsPopup {
  width: 150px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  margin-left: 150px;
  z-index: 3;
  background-color: white;
  margin-top: -50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.popupHeader {
  font-weight: 600;
  font-size: 14px;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupOption {
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.popupOption:hover {
  background-color: $gray200;
  border-radius: 7px;
  cursor: pointer;
}

.helpPopupDelete,
.helpPopupUnarchive,
.helpPopupAction {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 2%;
  z-index: 2;
}

.helpPopupDelete {
  margin-left: 20px;
}

.unarchiveQuestionButton:hover~.helpPopupUnarchive,
.moreActionsButton:hover~.helpPopupAction,
.deleteQuestionButton:hover~.helpPopupDelete {
  display: block;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}

.closeMoreActions {
  color: $gray400;
  cursor: pointer;
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
}

.moreActionsButton:hover {
  background-color: $gray200;
  border-radius: 7px;
}