@import "../../styles/_variables";

.gridMenu {
  width: 200px;
  height: 175px;
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  background-color: $gray100;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
}

.gridMenu::-webkit-scrollbar-track {
  border: 1px solid $cyan500;
  padding: 2px 0;
  background-color: $cyan500;
}

.gridMenu::-webkit-scrollbar {
  width: 5px;
}

.gridMenu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px white;
  background-color: white;
  border: 1px solid #b8b8b8;
}

.gridMenu:hover {
  background-color: $cyan500;
  border-color: $cyan500; // se colocar um important, a cor da borda no hover passa a ser todas azuis
}

.gridMenuTitle {
  text-align: center;
}

.gridMenuTitle h4 {
  text-transform: uppercase;
}

.gridMenuTitle img {
  max-width: 60px;
}
