@import "../../styles/_variables";

.listItem {
  padding: 4px;
  margin-bottom: 5px;
  cursor: grab;
}

.listContainer {
  margin-top: 10px;
}

.selectColorField {
  grid-template-columns: 1fr 1fr !important;
}

@media only screen and (min-width: 901px) {
  .searchIcon {
    margin-left: 4px;
  }

  .internalLabels {
    display: none;
  }

  .row {
    width: 95%;
  }

  .listItem {
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
  }

  .oneThird {
    width: 30% !important;
  }

  .firstRow {
    width: 55% !important;
  }

  .oneQuarter {
    width: 25% !important;
  }

  .oneFifth {
    width: 20% !important;
  }

  .twoFifth {
    width: 40% !important;
  }

  .highlightQuestion,
  .requiredQuestion {
    width: 35% !important;
  }

  .dragIcon {
    color: $cyan500;
  }

  .smallField {
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 900px) {
  .searchIcon {
    margin-top: 2px !important;
    margin-left: 5px;
  }

  .externalLabels {
    display: none;
  }

  .row {
    margin-bottom: 25px;
    border-bottom: 2px dashed $cyan500;
    position: relative;
  }

  .listControlContainer {
    text-align: center;
    padding-top: 20px;
  }

  .listControl {
    width: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .dragContainer {
    background-color: $cyan500;
    text-align: center;
    margin-bottom: 10px;
  }

  .dragIcon {
    color: $white;
    margin-top: 5px;
  }

  .smallField {
    width: 80% !important;
    margin-bottom: 5px;
  }

  .oneThird {
    width: 80% !important;
    margin-bottom: 5px;
  }

  .firstRow {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .oneQuarter {
    width: 100% !important;
  }

  .oneFifth {
    width: 100% !important;
  }

  .twoFifth {
    width: 100% !important;
    margin-bottom: 5px;
  }

  .highlightQuestion {
    left: 20%;
  }

  .requiredQuestion,
  .highlightQuestion {
    position: absolute;
    top: 200px;
    padding-top: 10px;
  }
}

.searchIcon {
  position: absolute;
  display: inline-block;
  margin-top: 6px;
  z-index: 1;
  color: $cyan500;
}

.searchField {
  padding-left: 30px;
  width: 100% !important;
}

.fieldPrefix {
  width: 50px;
}

.fieldPrefix,
.fieldAddQuestion {
  height: 36px !important;
}

.dropDownField {
  width: 35%;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.dropdownButton {
  position: absolute;
  display: inline-block;
  right: 0;
  margin-right: 17px;
  margin-top: 8px;
}

.wholeDivField {
  width: 100%;
}