@import "../../styles/_variables";

.headerContainer {
  display: flex;
}

.blocks {
  margin-top: 15px;
  cursor: grab;
  display: grid;
  grid-template-columns: auto 1fr;
}

.buttonOrange {
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9em;
  padding: 5px 15px;
  min-width: 125px;
  background: $orange100;
  color: $white;
  border: 1px solid $orange100;
  display: inline-flex;
  gap: 3px;
}

.languageField {
  display: flex;
  margin-bottom: 5px;
}

.buttonWhite {
  cursor: pointer;
  font-size: 0.9em;
  padding: 5px 15px;
  min-width: 125px;
  background: $white;
  color: $orange100;
  border: 1px solid $orange100;
  gap: 3px;
}

.addBlock {
  margin-left: auto;
}

.addLine {
  width: fit-content;
}

.addIcon {
  color: $white;
  align-self: center;
}

.deleteBlock {
  cursor: pointer;
  justify-self: end;
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  position: absolute;
  top: 24px;
  right: 40px;
  padding-right: 8px;
}

.deleteLine {
  cursor: pointer;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  display: contents;
}

.deleteIcon {
  filter: $gray300SVG;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: $orange100;
}

.dragIcon {
  color: $gray100;
}

.dragContainer {
  background-color: $cyan500;
  text-align: center;
  margin-bottom: 10px;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-end: 3;
  grid-row-start: 1;
  align-content: center;
  display: grid;
}

.row {
  border-bottom: 2px solid $gray200;
  display: grid;
  column-gap: 20px;
  margin: 10px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
}

.headerBlock {
  display: grid;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  gap: 10px;
  padding-bottom: 25px;
}

.afterLabel::after {
  content: ":";
  position: relative;
  font-weight: 700;
  font-size: 1.25rem;
  margin-left: -0.75rem;
}

.pattern {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-end: 2;
  grid-row-start: 2;
}

.academicArea {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-end: 2;
  grid-row-start: 2;
}

.designations,
.labels {
  grid-template-columns: 1fr 1fr;
  display: grid;
  gap: 15px;
  margin-top: 15px;
  // padding-top: 20px;
}

.boldCheckbox {
  display: inline-block;
  position: relative;
  margin-top: -10px;
  left: 60%;
}

.headLines {
  display: grid;
  gap: 15px;
  padding-bottom: 10px;
  width: 90%;
}

.lineControllers {
  display: contents;
}

.lineControl {
  display: block;
  position: absolute;
  width: 10%;
  right: 0px;
  margin-top: 25px;
  cursor: pointer;
}

.inputError {
  border: 1px solid red;
  border-radius: 6px;
}

.searchIcon {
  position: absolute;
  display: inline-block;
  margin: 5px;
  z-index: 1;
  width: 20px;
  color: $cyan500;
  align-self: center;
}

.searchField {
  width: 100% !important;
}

.inputContainer {
  display: inline-flex;
  width: 100%;
  position: relative;
}

.collapsed {
  display: none;
}

.subtitle {
  margin: 0;
  font-size: 0.85rem;
}