@import "../../styles/_variables";

.selectColorContainer {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  position: relative;
}
.colorDot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid $cyan500;
  position: absolute;
  right: 0px;
}

@media only screen and (max-width: 601px) {
  .selectField {
    width: 185% !important;
    height: 36px !important;
  }
}

.selectField {
  width: 150%;
  box-sizing: border-box;
  height: 36px !important;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.emptySelectField {
  color: #bbb;
}
