@import "../../styles/_variables";

.paginationContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: right;
  color: $cyan500;
  margin-right: 32px;
}

.backPage,
.previousPage {
  align-items: center;
  display: grid;
  cursor: pointer;
}
