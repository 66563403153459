@import "../../styles/_variables";

.FormContainer {
  background-color: $gray100;
  border-radius: 16pt;
  font-size: 18px;
  max-height: 580px;
  overflow-x: hidden;
}

@media only screen and (max-width: 600px) {
  .FormContainer {
    margin: 2rem auto;
    padding: 20px;
    width: 90%;
  }
}

@media only screen and (min-width: 600px) {
  .FormContainer {
    margin: 2rem auto;
    padding: 30px;
    width: 75%;
  }
}

@media only screen and (min-width: 1200px) {
  .FormContainer {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 70% !important;
  }
}

@media only screen and (min-width: 1700px) {
  .FormContainer {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 1000px !important;
  }
}