@import "../../styles/_variables";

.pageHeader {
  display: flex;
  width: 100%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 30px;
}

.usersWithAccessList{
  font-size: 12px;
  background-color: white;
  border-radius: 5px;
  border: solid 1px hsl(0, 0%, 80%); 
  padding: 7.5px; 
  margin-bottom:5px;
  min-height: 33px;
}

.button {
  border-radius: 8px;
  cursor: pointer;
  font-size: 0.9em;
  padding: 5px 15px;
  min-width: 125px;
}

.createQuestionButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}

.activeButton {
  background: #fff;
  color: #ff8966;
  border: 1px solid #ff8966;
}

.inactiveButton {
  background: #fff;
  color: #0064a2;
  border: 1px solid #0064a2;
}

.disabledButton {
  background: $gray100;
  color: $gray300;
  border: 1px solid $gray200;
  cursor: not-allowed;
}

.container {
  margin-top: 15px !important;
  width: 90% !important;
}

.selectUsers {
  font-size: 14px;
  line-height: 1.5714285714285714;  
}

.recordContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
}

.closeButton {
  margin-left: auto;
  position: relative;
  padding-right: 1.5em;
}

.recordTitle {
  grid-column-start: 1;
  width: 100%;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}

.school {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
}

.area {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}

.client {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}

.program {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
}

.pptFooterChooseImageField{ 
  width: 100%;
}

.orderClass {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}

.pptFooter {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}

.language {
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 1;
}

.usersWithAccess {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 5;
}

.responsiblesUsers {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 5;
}

.logoContainer {
  display: inline-flex;
  gap: 3rem;
}

.headerRow {
  padding-bottom: 15px;
}

.initialDate {
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
}

.finalDate {
  display: inline-flex;
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 3;
  gap: 5px;
}

.editIcon {
  color: $cyan500;
  align-self: end;
  cursor: pointer;
}

.saveButton {
  padding: 0px 50px 0px 50px;
}

.cancelButton {
  padding: 0px 50px 0px 50px;
}

.buttonsContainer {
  display: flex;
  padding: 1.5em;
  align-items: center;
}

.disableFlag {
  filter: grayscale(1);
  cursor: default;
  opacity: 0.7;
}

.languageButton {
  cursor: pointer;
  display: inline-flex;
  margin-right: 10px;
}

.multiLanguageField {
  width: 100%;
  margin-right: 1%;
  display: inline-flex;
}

.multiLanguageTextField {
  margin-left: 5px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.obs {
  margin-bottom: 2%;
  margin-top: 3%;
}

.obsRow {
  display: inline-block;
  width: 50%;
  margin-left: 3%;
  border-left: 3px solid grey;
  padding-left: 2%;
}

.moreHeader {
  display: inline-flex;
  width: 100%;
}

.buttonDisabled,
.buttonDisabled:hover {
  background-color: $gray300;
  cursor: default;
  border: 2px solid $gray300;
}

.statusContainer {
  text-align: right;
  margin-right: 6%;
}

.statusLabel {
  font-weight: bold;
  color: $gray300;
}

.statusValueLabel {
  font-weight: bold;
  color: $orange100;
}

.backAssessmentButton,
.advanceButton {
  color: $cyan500;
  cursor: pointer;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 3px;
}

.helpPopupBackAssessment,
.helpPopupAdvance {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  z-index: 9999;
  // margin-left: 64%;
  // top: 24%;
}

.helpPopupBackReady {
  margin-left: 50%;
}

.backAssessmentButton:hover~.helpPopupBackAssessment,
.advanceButton:hover~.helpPopupAdvance {
  display: block;
}

.newItemPopUp {
  width: 500px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  max-height: 400px;
  overflow: auto;
  top: 25%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 17%;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupRow {
  padding-top: 15px;
  display: flex;
  gap: 30px;
  margin: 20px;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.initialDatePopup {
  display: flex;
  gap: 5px;
}

.skipButton {
  color: $cyan500;
  cursor: pointer;
  margin-left: 1rem;
  border: 2px solid $cyan500;
  border-radius: 8px;
  justify-content: center;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .recordContainer {
    display: inline-block;
    width: 100%;
  }

  .container {
    width: 80% !important;
  }

  .school,
  .area,
  .program,
  .language,
  .usersWithAccess,
  .initialDate,
  .finalDate,
  .color,
  .client {
    padding-top: 20px;
  }
}