@import "../../styles/_variables";

.tabsContainer {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabsButton,
.tabsButtonNew {
  min-width: 172px;
  // padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
}

.tabsButtonNew {
  position: absolute;
  top: 6.5rem;
}

.tabsButton {
  border: 1px solid;
  height: 3.5em;
}

.tabsButton:hover,
.tabsButtonSelected {
  border: 1px solid #ff521f;
  color: #ff521f;
  width: 7.5em;
  padding: 0.25em 1em;
  background: #fff;
  cursor: pointer;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.newItemPopUp {
  width: 330px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  max-height: 400px;
  overflow: auto;
  top: 25%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 20%;
}

.permissionPopup {
  width: 330px;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 3;
  background-color: white;
  height: 500px;
  overflow: auto;
  top: 15%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  margin-left: 20%;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupRow {
  padding-top: 15px;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 15px;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
  margin-top: 10px;
}

.permissionUserData {
  display: block;
  padding-left: 10px;
}

.permissionUserPhoto {
  display: block;
}

.accessGrantedDate {
  font-size: 12px;
}

.accessList {
  min-height: 280px;
  max-height: 280px;
  overflow: scroll;
  margin-top: 10px;
}

.removePermission {
  font-size: 12px;
  cursor: pointer;
}

.userInformation {
  display: inline-flex;
  margin-top: 25px;
  line-height: 16px;
}

.table {
  display: block;
  height: 76vh;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableTitle {
  text-align: left;
  padding-left: 30px;
}

.tableRow {
  height: 3rem;
}

.itemRow {
  color: $gray400;
  padding-left: 20px;
  padding-right: 10px;
}

.itemTitle {
  font-weight: 600;
  padding-left: 30px;
}

.actionsContainer {
  text-align: center;
}

.editItemButton {
  color: $cyan500;
  cursor: pointer;
}

.helpPopupEdit {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 130px;
}

.editItemButton:hover~.helpPopupEdit {
  display: block;
}

.primaryButtonDisabled {
  background: $gray300;
  border: 2px solid $gray400;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}