@import "../../styles/_variables";

.dashboard {
  display: grid;
  padding-left: 25px;
  grid-template-rows: auto;
  height: 100%;
}

.dashboardWelcome {
  color: $cyan500;
  font-size: 1.25rem;
}

.dashboardElements {
  visibility: hidden;
  justify-content: center;
  display: flex;
}

.dashboardGrid {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}