@import "../../styles/_variables";

.radioFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}


.radioDefault {
  appearance: none;
  margin: 0 1rem;
  width: 1.25rem;
  height: 1.25rem;
  background: $white;
  box-shadow:
    inset 0 0 0 .15em $white,
    0 0 0 .2em;
  border-radius: 50%;
  transition: .2s;
  cursor: pointer;
  color: $gray200;

  &:hover,
  &:checked {
    box-shadow:
      inset 0 0 0 .2em $white,
      0 0 0 .1em;
  }

  &:checked {
    box-shadow:
      inset 0 0 0 .4em $white,
      0 0 0 .3em $cyan600;
  }

  &:focus {
    outline: 0;
  }
}