@import "../../styles/_variables";


.menuItemsOrangeCurrentPage {
    background-color: $orange100;
    color: $white;
}

.iconOrange {
    filter: $whiteSVG;
    max-width: 20px !important;
}

.menuItemsWhiteCurrentPage {
    background-color: $cyan500;

    >.iconCyan {
        filter: $whiteSVG;
    }

    >.labelCyan {
        color: white;
    }
}