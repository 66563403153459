@import "../../styles/_variables";

.tableControlContainer {
  display: flex;
  gap: 1rem;
  margin: 0.5rem auto;
}

.search {
  position: absolute;
  right: 15%;
  padding-right: 1.5em;
  width: 12em;
}

.buttonGo {
  position: absolute;
  margin-left: -7.5px;
  height: 32px;
  border-radius: 0px 5px 5px 0px;
  background-color: $cyan500;
  color: white;
  border: none;
  width: 30px;
  cursor: pointer;
}

.searchInput {
  border: 1px solid $cyan500;
  color: $cyan500;
  background: transparent;
}

.bulkActions {
  width: 10em;
}

.showByPageContainer {
  gap: 5px;
}

.showByPage {
  width: 10em;
}

@media only screen and (max-width: 650px) {
  .selectsContainer {
    gap: 10px;
  }

  .search {
    grid-row-start: 1;
  }
}

.filter {
  position: absolute;
  margin-top: -27px;
  right: 0px;
}