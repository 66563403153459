@import "../../styles/_variables";

.menuItemsCurrentPage {
    background-color: white;
}

.iconCurrentPage {
    filter: $cyan500SVG;
}

.labelCurrentPage {
    color: $cyan500;
}