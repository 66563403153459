@import "../../styles/_variables";

.container {
  width: 90% !important;
}

.saveButton {
  padding: 0px 50px 0px 50px;
}

.cancelButton {
  padding: 0px 50px 0px 50px;
}

.pageHeader {
  display: flex;
  width: 100%;
}

.createQuestionButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}

.closeButton {
  margin-left: auto;
  position: relative;
  padding-right: 1.5em;
}

.buttonsContainer {
  display: flex;
  padding: 1.5em;
}

.archiveButton {
  background: none;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid $red800;
  margin-right: 5px;
  margin-top: 4px;
  vertical-align: top;
  height: 27px;
}

.archiveIcon {
  color: $red800;
  vertical-align: top;
}

.checkAsFavorite {
  color: $cyan500;
  font-size: 24px;
  top: 4px;
  position: relative;
}

.favoriteContainer {
  margin-top: 20px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.buttonDisabled,
.buttonDisabled:hover {
  background-color: $gray300;
  cursor: default;
  border: 2px solid $gray300;
}