@import "../../styles/_variables";

.questTitle {
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.3rem;
}

.questionText {
    margin: 1rem 0 1rem 1rem;
    font-size: 1.25rem;
}

.multipleChoiceText {
    margin: 1rem 0 1rem 1rem;
    display: flex;
    align-items: center;
    font-size: 1.25rem;
}

.rateQuestion {
    display: flex;
}

.npsHeader {
    font-weight: 600;
}

.radioText {
    display: inline-flex;
    margin: 0.5rem 0;
    font-size: 1.25rem;
}

.radioDefault {
    appearance: none;
    margin: 0 1rem;
    width: 1rem;
    height: 1rem;
    background: $white;
    box-shadow:
        inset 0 0 0 .15em $white,
        0 0 0 .2em;
    border-radius: 50%;
    transition: .2s;
    cursor: pointer;
    color: $gray200;

    &:hover,
    &:checked {
        box-shadow:
            inset 0 0 0 .2em $white,
            0 0 0 .1em;
    }

    &:checked {
        box-shadow:
            inset 0 0 0 .4em $white,
            0 0 0 .3em $cyan600;
    }

    &:focus {
        outline: 0;
    }
}

.npsSquare {
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    margin: 0 0.2rem;
    position: relative;
    background-color: $white;
    border-radius: 0.25rem;
    cursor: pointer;
}

.npsRadio {
    display: none;
}

.npsSquareSelected {
    text-align: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.5rem;
    margin: 0 0.2rem;
    position: relative;
    border-radius: 0.25rem;
    cursor: pointer;
    background: $gray400;
    color: white;
}

.npsForm {
    width: 100%;
    height: 4rem;
    background: $gray100;
    display: flex;
    position: relative;
    align-items: center;
    padding: 0.5rem;
    justify-content: center;
    flex-direction: row;
    border-radius: 1rem;
    font-size: 1.25rem;
}

.npsText {
    text-align: left;
    font-size: 1.3rem;
}

.questionContainer {
    margin: 1rem 0;
}

.labelQuestion {
    margin-bottom: 0.2rem;
    font-weight: 600;
    font-size: 1.5rem;
}

.npsLabel {
    display: contents;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-input-lg {
    font-size: 1.25rem !important;
}

@media only screen and (min-width: 769px) and (max-width: 2560px) {
    .npsForm {
        width: 75%;
        margin: 0 auto;
    }

    .rateQuestion {
        justify-content: flex-start;
    }

}