@import "../../styles/_variables";

.uploadImageField {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
  width: fit-content;
}

.inputUpload {
  display: none;
}

.uploadImageThumb {
  text-align: center;
}

.uploadImageTitle {
  text-align: center;
  font-size: 18px;
}

.uploadImageLabel {
  color: $cyan500;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
}

.thumbnailContainer {
  width: auto;
  border: 1px solid $gray400;
  border-radius: 5px;
  background-color: white;
  font-size: 10px;
  text-align: center;
  height: 95px;
  position: relative;
  height: 10rem;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlayOff {
  position: fixed;
  display: none;
}

.mediaPopUp {
  width: 50%;
  border-radius: 7px;
  padding: 15px;
  position: fixed;
  z-index: 3;
  background-color: white;
  max-height: 500px;
  top: 35%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  left: 25%;
  overflow: hidden;
  overflow-y: auto;
}

.header {
  font-weight: 600;
  border-bottom: 1px solid rgb(177, 177, 177);
}

.popupRow {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.popupFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
}

.tabsButtonFooter {
  height: auto !important;
  font-size: 12px !important;
  min-width: 80px;
}

.imagePreview {
  width: 80px;
  height: fit-content;
  align-self: center;
  cursor: pointer;
}

.imageSelected {
  box-shadow: rgba(53, 50, 50) 0px 5px 15px;
  border-radius: 8px;
}



.thumbImg {
  width: 100% !important;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16pt;
  color: $gray400;
}

.closeButton {
  width: 30px;
  position: absolute;
  z-index: 1;
  right: -2.2rem;
  display: inline-block;
  margin-right: 3px;
  color: $gray400;
}

.uploadButton {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.searchIcon {
  position: absolute;
  display: inline-block;
  margin: 5px;
  z-index: 1;
  width: 20px;
  color: $cyan500;
  align-self: center;
}

.searchField {
  width: 100% !important;
}

.inputContainer {
  margin-top: 1rem;
}