@import "../../styles/_variables";

.fieldContainer {
  margin-bottom: 15px;
}

.checkboxHabilitarPeso {
  top: 3px;
  position: relative;
}

.languagesContainer {
  display: flex;
  gap: 20px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.archiveButton {
  background: none;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid $red800;
  margin-right: 5px;
  margin-top: 4px;
  vertical-align: top;
  height: 27px;
}

.archiveIcon {
  color: $red800;
  vertical-align: top;
}

.npsContainer {
  gap: 20px;
  display: grid;
}

.createQuestionButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}

@media only screen and (min-width: 900px) {
  .columnsContainer {
    display: contents;
  }

  .col6 {
    width: 50%;
  }

  .col3 {
    width: 25%;
  }

  .col4 {
    width: 33%;
  }

  .col8 {
    width: 80%;
    padding-left: 15px;
  }

  .buttonsContainer {
    display: flex;
    padding: 1.5em;
  }
}

@media only screen and (max-width: 901px) {
  .col6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .col3 {
    width: 100%;
    margin-bottom: 20px;
  }

  .col4 {
    width: 100%;
    margin-bottom: 20px;
  }

  .col8 {
    width: 100%;
    margin-bottom: 20px;
  }

  .buttonsContainer {
    display: flex;
    padding: 1.5em;
  }
}

@media only screen and (max-width: 600px) {
  .buttonsContainer {
    display: block;
    padding: 1.5em;
  }

  .saveButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .cancelButton {
    width: 100%;
    width: 100%;
  }
}

.saveButton {
  padding: 0px 50px 0px 50px;
}

.cancelButton {
  padding: 0px 50px 0px 50px;
}

.pageHeader {
  display: flex;
  width: 100%;
}

.closeButton {
  margin-left: auto;
  position: relative;
  padding-right: 1.5em;
}