.optionField {
  width: 100%;
}

.optionLanguageContainer {
  display: flex;
  gap: 10px;
}

@media only screen and (min-width: 601px) {
  .optionField {
    width: 100%;
  }

  .optionFieldColumnWithoutWeight {
    width: 95% !important;
  }

  .weightOptionField {
    width: 100%;
  }

  .weightOptionFieldColumn {
    width: 25%;
    vertical-align: bottom;
    padding-left: 10px;
    padding-right: 10px;
  }

  .controlColumn {
    width: 5%;
    vertical-align: bottom;
  }
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .optionFieldColumnContainer {
    display: inline;
  }
  .optionFieldColumnWithoutWeight {
    width: 90% !important;
  }
  .weightOptionFieldColumn {
    width: 20%;
    padding-left: 0px;
  }
  .controlColumn {
    width: 20%;
    vertical-align: midle;
  }
  .teste {
    justify-content: right;
  }
}

@media only screen and (max-width: 400px) {
  .optionFieldColumnWithoutWeight {
    width: 75%;
  }
  .weightOptionFieldColumn {
    width: 20%;
    vertical-align: bottom;
    padding-left: 10px;
    padding-right: 10px;
  }
  .controlColumn {
    width: 25%;
  }
  .optionField {
    height: 60px;
  }
  .weightOptionField {
    height: 60px;
  }
}
