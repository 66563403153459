@import "../../styles/_variables";

.FormContainerPdf {
  background-color: $gray100;
  border-radius: 16pt;
  font-size: 18px;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .FormContainerPdf {
    margin: 20px 20px 20px 20px;
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .FormContainerPdf {
    margin: 50px 50px 50px 50px;
    padding: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .FormContainerPdf {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 70% !important;
  }
}

@media only screen and (min-width: 1700px) {
  .FormContainerPdf {
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    position: relative;
    width: 1000px !important;
  }
}