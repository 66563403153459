@import "../../styles/_variables";

.ant-btn-primary {
    background-color: $green500 !important;
    width: 7rem;
}

.ant-btn-default {
    background-color: $red300 !important;
    color: $white;
    width: 7rem;

    &:hover {
        color: $white !important;
        border: 0 !important;
    }
}

.ant-btn-text {
    display: contents;
}

.ant-modal-title {
    display: flex;
    justify-content: center
}

.ant-modal-footer {
    display: flex;
    justify-content: space-between;
}

.deleteButton{
    color:#e25858;
    cursor:pointer; 
  }