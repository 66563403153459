@import "../../styles/_variables";

.layoutMenu {
  background-color: $cyan500;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: 3;
  width: 270px;
  display: grid;
  grid-template-rows: auto 2fr;
  min-height: 100vh;
}

.layoutUser {
  text-align: center;
}

.layoutContainer { 
  margin-left:auto;
  margin-right:auto;
}

.layoutMenuItems {
  margin: 15px;
}


@media only screen and (min-width: 1301px) {
  .layoutContainer { 
    width:70%;
  }
}

@media only screen and (max-width: 1300px) {
  .layoutContainer { 
    width:90%;
  }
}

@media only screen and (max-width: 900px) {
  .layoutContainer { 
    width:100%;
  }
}
 