@import "../../styles/_variables";

.userNameMenuWhite {
  color: white;
  width: auto;
}

.userNameMenuCyan {
  color: $cyan500;
  width: auto;
  font-weight: 600;
}

.userNameEdit {
  max-width: 18px;
  filter: $whiteSVG;
  margin-left: 5px;
  cursor: pointer;
}
