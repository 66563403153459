@import "../../styles/_variables";

.funnelIcon{
    color: $cyan500;
    cursor:pointer;
}

.overlay {
    position: fixed; 
    display: block; 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 2;  
}

.overlayOff {
    position: fixed; 
    display: none;
}

.filterPopUp{
    width: 280px; 
    border-radius: 7px;
    padding:15px;
    position: absolute;
    margin-left: -245px;
    z-index:3;
    background-color: white;
    max-height: 400px;
    overflow: auto;
}
 

.header{
    font-weight: 600;
    border-bottom:1px solid rgb(177, 177, 177);
}

.headerParameter{
    font-weight: 600; 
    text-transform: capitalize;
}

.cleanFilter{
    position: absolute;
    margin-top: -20px;
    margin-left: auto !important;
    display: block;
    right: 20px;
    font-size: 12px;
    font-weight: 400;
    color: $orange100;
    cursor:pointer;
}

.square{
    width:18px;
    height:18px;
    border-radius: 5px;
    border:1px solid $orange100;
    display: inline-block;
    top:3.5px;
    margin-right: 5px;
    position: relative;
}

.filterBody{
    margin-top:5px;
}
 
.parameterItem{
    margin-top:7.5px;
}

.filterableValuesList{
    background-color:#E9EaEa;
    border-radius: 7px;
    padding:7.5px;
    font-weight: 400;
    font-size: 14px;
    margin-top:10px;
}

.filterableValue{
    display:inline-block;
    width: 50%;
    margin-bottom:5px;
}
 