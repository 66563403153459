$cyan500: #0064a2;
$cyan600: #084d6e;
$cyan900: #004776;
$gray100: #f2f2f2;
$gray200: #d9d9d9;
$gray300: #939598;
$gray400: #696a6b;
$red100: #ebc0bf;
$red300: #e25858;
$red700: #ba1411;
$red800: #a61714e8;
$red900: #680c0ae8;
$green100: #bdddcb;
$green500: #2e8b57;
$green700: #697050;
$orange100: #ff8966;
$orange300: #ff521f;
$white: #ffffff;

//filters to change SVGs colors
// link to generate: https://codepen.io/sosuke/pen/Pjoqqp
$cyan500SVG: invert(23%) sepia(99%) saturate(1718%) hue-rotate(183deg)
  brightness(91%) contrast(101%);
$cyan600SVG: invert(24%) sepia(99%) saturate(690%) hue-rotate(166deg)
  brightness(104%) contrast(91%);
$whiteSVG: invert(100%) sepia(0%) saturate(7451%) hue-rotate(182deg)
  brightness(113%) contrast(106%);
$red300SVG: invert(49%) sepia(12%) saturate(2868%) hue-rotate(314deg)
  brightness(92%) contrast(91%);
$red700SVG: invert(18%) sepia(88%) saturate(2465%) hue-rotate(346deg)
  brightness(90%) contrast(110%);
$green500SVG: invert(47%) sepia(9%) saturate(2637%) hue-rotate(94deg)
  brightness(94%) contrast(93%);
$green700SVG: invert(40%) sepia(8%) saturate(1298%) hue-rotate(33deg)
  brightness(101%) contrast(85%);
$orange100SVG: invert(76%) sepia(100%) saturate(7024%) hue-rotate(317deg)
  brightness(100%) contrast(85%);
$orange300SVG: invert(40%) sepia(22%) saturate(5982%) hue-rotate(348deg)
  brightness(101%) contrast(101%);
$gray200SVG: invert(99%) sepia(4%) saturate(0%) hue-rotate(108deg)
  brightness(107%) contrast(70%);
$gray300SVG: invert(61%) sepia(6%) saturate(155%) hue-rotate(177deg)
  brightness(96%) contrast(92%);
$gray400SVG: invert(41%) sepia(1%) saturate(489%) hue-rotate(95deg)
  brightness(101%) contrast(91%);

//to exporting variables allow the use on js files
:export {
  cyan500: $cyan500;
  cyan500SVG: $cyan500SVG;
  cyan600: $cyan600;
  cyan600SVG: $cyan600SVG;
  red300: $red300;
  red300SVG: $red300SVG;
  red700: $red700;
  red700SVG: $red700SVG;
  green500: $green500;
  green500SVG: $green500SVG;
  green700: $green700;
  green700SVG: $green700SVG;
  orange100: $orange100;
  orange100SVG: $orange100SVG;
  orange300: $orange300;
  orange300SVG: $orange300SVG;
  gray300: $gray300;
  gray300SVG: $gray300SVG;
  white: $white;
  whiteSVG: $whiteSVG;
  gray400: $gray400;
  gray400SVG: $gray400SVG;
  gray200SVG: $gray200SVG;
}
