@import "../../styles/_variables";

.tabsContainer {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tabsButton,
.tabsButtonNew {
  min-width: 172px;
  // padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
}

.tabsButton {
  border: 1px solid;
}

.tabsButton:hover,
.tabsButtonSelected {
  border: 1px solid #ff521f;
  color: #ff521f;
  width: 7.5em;
  height: 3em;
  padding: 0.25em 1em;
  background: #fff;
  cursor: pointer;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.table {
  display: block;
  height: 80vh;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.tableTitle {
  text-align: left;
  padding-left: 30px;
}

.tableRow {
  height: 50px;
}

.itemRow {
  color: $gray400;
  padding-left: 20px;
  padding-right: 10px;
}

.itemTitle {
  font-weight: 600;
  padding-left: 30px;
}

.actionsContainer {
  text-align: center;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
  cursor: pointer;
}

.helpPopupUnarchive,
.helpPopupDelete {
  display: none;
  position: absolute;
  font-size: 14px;
  background-color: black;
  border-radius: 3px;
  color: $white;
  padding-left: 2px;
  padding-right: 2px;
  margin-left: 1%;
  z-index: 2;
}

.unarchiveAssetButton {
  filter: $green500SVG;
  cursor: pointer;
}

.unarchiveAssetButton:hover~.helpPopupUnarchive,
.deleteAssetButton:hover~.helpPopupDelete {
  display: block !important;
}

.imagePreview {
  width: 30%;
  max-width: 60px;
}

.alignCenter {
  text-align: center;
}

.colorDot {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}

.deleteAssetButton {
  display: none !important;
  color: transparent;
  // color: $red800;
}

.backButton {
  font-size: 14px !important;
  line-height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid $orange300;
  color: $orange300;
}

.backButton:hover {
  border: 1px solid $orange300;
  background-color: $orange300;
  color: $white;
}

.backIcon {
  margin-left: 5px;
}