@import "../../styles/_variables";

.saveButton {
  padding: 0px 50px 0px 50px;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.cancelButton {
  padding: 0px 50px 0px 50px;
}

.pageHeader {
  display: flex;
  width: 100%;
}

.closeButton {
  margin-left: auto;
  position: relative;
  padding-right: 1.5em;
}

.createQuestionButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.addIcon {
  color: $white;
  vertical-align: middle;
  margin-right: 3px;
}

.buttonsContainer {
  display: flex;
  padding: 1.5em;
}

.container {
  display: grid;
  max-width: 1000px;
  column-gap: 20px;
  row-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}

.name {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.lastName {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
}

.userEmail {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
}

.function {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 2;
}



.uploadAvatar {
  display: grid;
  justify-content: center;
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 5;
}

.passwordFirstLogin {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 4;
  grid-row-end: 4;
}

.sendEmail {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 5;
}

@media only screen and (max-width: 750px) {
  .password {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .passwordConfirm {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .function {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 4;
  }

  .uploadAvatar {
    display: grid;
    justify-content: center;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  .passwordFirstLogin {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 5;
    grid-row-end: 5;
  }

  .sendEmail {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 6;
  }
}

@media only screen and (max-width: 520px) {
  .container {
    display: block;
    padding: 1.5em;
  }

  .uploadAvatar {
    display: block;
    padding-bottom: 15px;
  }

  .userEmail,
  .name,
  .lastName,
  .password,
  .passwordConfirm,
  .function,
  .uploadAvatar,
  .passwordFirstLogin,
  .sendEmail {
    padding-top: 10px;
  }

  .buttonsContainer {
    display: block;
    padding: 1.5em;
  }

  .saveButton {
    width: 100%;
    margin-bottom: 10px;
  }

  .cancelButton {
    width: 100%;
    width: 100%;
  }
}