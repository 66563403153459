.user {
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 20px;
}

.userNameEdit {
  display: flex;
  justify-content: center;
}

.userAvatar {
  width: 4rem;
}