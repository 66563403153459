@import "../../styles/_variables";

.uploadAvatarField {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 5px;
  width: fit-content;
}

.inputUpload {
  display: none;
}

.uploadAvatarImage {
  text-align: center;
}

.uploadAvatarTitle {
  text-align: center;
  font-size: 16x;
  white-space: nowrap;
}

.uploadAvatarLabel {
  color: $cyan500;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;
}
