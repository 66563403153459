.fieldLarge {
  width: 33.75em;
  height: 4em;
  border: 2px solid #ff8966;
  border-radius: 8px;
  background: transparent;
  transition: 0.2s;
}

//Use !important to ignore default hover from antd

.fieldLarge:hover {
  border: 2px solid #ff521f !important;
}

.fieldMedium {
  width: 30em;
  height: 3em;
  border: 2px solid #ff8966;
  border-radius: 8px;
  background: transparent;
  transition: 0.2s;
  padding-left: 5px;
}

//Use !important to ignore default hover from antd

.fieldMedium:hover {
  border: 2px solid #ff521f !important;
}

.fieldSmallBlue {
  width: 15em;
  height: 3em;
  border: 2px solid #0064a2;
  border-radius: 8px;
  background: transparent;
  transition: 0.2s;
}

//Use !important to ignore default hover from antd

.fieldSmallBlue:hover {
  border: 2px solid #004776 !important;
}

::placeholder {
  vertical-align: middle;
  font-size: 1em;
  font-weight: 500;
}
