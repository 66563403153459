@import "../../styles/_variables";


.notFound {
  padding: 3rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: auto;
  width: 85%;
}

.headerLogo {
  margin-bottom: 5rem;
  max-width: 30rem;
}

.message {
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.messageSubtitle {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.redirect {
  cursor: pointer;
  color: $cyan500;
}

@media screen and (min-width: 1024px) {
  .notFound {
    width: 50%;
  }

}

@media screen and (min-width: 375px) and (max-width: 1023px) {
  .message {
    font-size: 1.35rem;
  }

  .messageSubtitle {
    font-size: 1rem;
  }

  .headerLogo {
    max-width: 20rem;
  }

}