@import "../../styles/_variables";

.surveyStatusItem {
  display: flex;
}

.itemImg {
  max-width: 24px;
  filter: $cyan500SVG;
}

.number {
  margin-left: 5px;
  margin-right: 8px;
  width: 60px;
  text-align: center;
  color: $cyan500;
  font-weight: 700;
}

.label {
  color: $cyan500;
}
