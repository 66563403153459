@import "../../styles/_variables";

.surveyBody {
  font-family: 'Poppins', sans-serif;
}

.teacherInfo {
  display: flex;
  margin: 1rem auto;
  padding: 1rem;
  min-height: 8rem;
  width: 97%;
  background-color: $gray100;
  border: 1px solid $gray200;
  border-radius: 1rem;
  flex-direction: column;
  font-size: 1.25rem;
}

.noInfo {
  display: flex;
  margin: 1rem auto;
  padding: 0.25rem;
  width: 97%;
  background-color: $gray100;
  border: 1px solid $gray200;
  border-radius: 1rem;
}

.infoLine {
  display: inline-block;
  margin-bottom: 0.25rem;
  max-width: 75%;
}

.textBold {
  max-width: 75%;
  display: inline-block;
  font-weight: 700;
}

.text {
  font-weight: 400;
}

.questionsContainer {
  margin: 5rem auto;
}

.questionsBlock {
  max-width: 90%;
  margin: 0.5rem auto;
}

.logoContainer {
  background-color: $white;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 17rem;
  position: relative;
  top: 0;
  z-index: 3;
}

.logoSize {
  max-width: 9rem;
  margin: 0.5rem 0;
}


.OnelogoSize {
  max-width: 35vw;
  margin: 0.5rem 0;
}

.logoContainerFixed {
  background: #fff;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  height: 8rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  border-bottom: 1rem solid $gray200;
}

.logoSizeFixed {
  max-width: 7rem;
  margin: 0.5rem 0;
}

.OnelogoSizeFixed {
  max-width: 20vw;
  margin: 0.5rem 0;
}

.caption {
  display: flex;
  font-size: 1rem;
  position: absolute;
  right: 3%;
  bottom: -55%;
  background: #c2c2c2;
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.captionBlock {
  position: absolute;
  z-index: 8;
  color: #000;
  bottom: -112%;
  background: #c2c2c2;
  width: 97%;
  height: auto;
  padding: 0.1rem;
  border-radius: 8px;
  color: #fff;
  padding: 1rem;
}

.infoSurvey {
  display: block;
  text-align: center;
}

.titleSurvey {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.explanation {
  margin: 1.5rem auto;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30%;
  text-align: center;
}

.selectLanguageContainer {
  text-align: center;
  min-width: 15rem;
  margin: 2rem auto;
}

.adjustContainer {
  display: flex;
}

.teacherPhoto {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  right: 10%;
}

.teacherPhotoPdf {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  position: absolute;
  right: 29%;
}

.divTeacherPhoto {
  margin-right: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.sessionHeaderLeft {
  height: 6.75rem;
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.headerLeftInfo {
  margin-left: 0.25rem;
}

.sessionHeaderRight {
  height: 6.75rem;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.divisorCard {
  height: 4rem;
  width: 0.2rem;
  background-color: $cyan500;
  margin: auto 0.25rem;
}

.divisorCardSubtitle {
  height: 4rem;
  width: 0.2rem;
  background-color: $cyan500;
  margin: auto 0.25rem;
}

.bold {
  font-weight: 700;
}

.designation {
  font-weight: 700;
  margin-right: 0.25rem;
}

.labelBold {
  display: flex;
  font-weight: 700;
  margin-right: 0.25rem;
}

.label {
  display: flex;
  margin-right: 0.25rem;
}

.subtitle {

  font-size: 0.85rem;
}

.sendButton {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 7;
}

.InfoOverlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
}

.hideInfo {
  position: absolute;
  right: 1%;
  bottom: 4%;
}

.overlayOff {
  position: fixed;
  display: none;
}

.optionsButton {
  display: flex;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 6;
}

.newItemPopUp {
  width: 13rem;
  border-radius: 7px;
  padding: 15px;
  position: absolute;
  z-index: 8;
  background-color: white;
  height: 13rem;
  overflow: auto;
  top: 5%;
  display: block;
  padding-bottom: 0;
  text-align: start;
  right: 1rem;
}

.popUpDivisor {
  margin: 0.4rem 0;
  border-bottom: 1px solid;
}

.titlePopUp {
  font-size: 1.25rem;
  font-weight: 700;
}

.optionsPopUp {
  height: 1.75rem;

  &:hover {
    text-align: start;
    background-color: $gray200;
    width: 100%;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
}

.progressBar {
  height: 20px;
  background-color: $cyan500;
  overflow: hidden;
  position: fixed;
  z-index: 2;
  margin-top: -10rem;
}



.barraProgresso {
  position: fixed;
  z-index: 7;
  /* Ancora a barra de progresso na base do cabeçalho */
  top: 7rem;
  left: 0;

  /* Largura inicial da barra de progresso */
  width: 0;
  height: 1rem;
  /* Altura da barra de progresso */
  background: $cyan500;
}

.content {
  /* padding-top é definido pelo JavaScript */
}


@media only screen and (min-width: 2560px) {

  .questionsBlock {
    max-width: 40%;
    margin: 0.5rem auto;
  }

  .teacherInfo {
    width: 50%;
  }

  .noInfo {
    width: 50%;
  }

  .teacherPhoto {
    right: 41%;
  }

  .label {
    display: block;
  }

  .explanation {
    width: 20%;
  }

  .selectLanguageContainer {
    width: 5%;
  }



}

@media only screen and (min-width: 2098px) and (max-width: 2559px) {

  .label {
    display: block;
  }

  .explanation {
    width: 20%;
  }

  .selectLanguageContainer {
    width: 5%;
  }



}

@media only screen and (min-width: 1440px) and (max-width: 2097px) {

  .teacherInfo {
    width: 75%;
  }

  .noInfo {
    width: 75%;
  }

  .teacherPhoto {
    right: 29%;
  }

  .questionsBlock {
    max-width: 90%;
    margin: 1rem 20%;
  }

  .label {
    display: block;
  }

  .explanation {
    width: 46%;
  }

  .selectLanguageContainer {
    width: 6%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {

  .header {
    display: inline-block;
  }

  .teacherInfo {
    width: 75%;
  }

  .noInfo {
    width: 75%;
  }

  .teacherPhoto {
    right: 29%;
  }

  .questionsBlock {
    max-width: 90%;
    margin: 1rem 20%;
  }

  .label {
    display: block;
  }

  .explanation {
    width: 50%;
  }

  .selectLanguageContainer {
    width: 10%;
  }

  .selectLanguageContainer {
    width: 8%;
  }



}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .header {
    display: inline-block;
  }

  .infoSurvey {
    margin: 2rem 0 0.5rem 0;
  }

  .teacherInfo {
    width: 75%;
  }

  .noInfo {
    width: 75%;
  }

  .teacherPhoto {
    width: 6rem;
    height: 6rem;
    right: 29%;
  }

  .questionsBlock {
    max-width: 90%;
    margin: 1rem 20%;
  }

  .label {
    display: block;
  }

  .explanation {
    width: 55%;
  }

  .selectLanguageContainer {
    width: 12%;
  }



}

@media only screen and (min-width: 521px) and (max-width: 767px) {

  .header {
    display: inline-block;
  }

  .infoSurvey {
    margin: 2rem 0 0.5rem 0;
  }

  .npsForm {
    width: 60%;
    margin: 0 auto;
  }

  .questionsBlock {
    max-width: 90%;
    margin: 1rem 20%;
  }

  .label {
    display: block;
  }

  .teacherPhoto {
    right: 23%;
  }

  .explanation {
    width: 85%;
  }

  .selectLanguageContainer {
    width: 15%;
  }

}

@media only screen and (min-width: 320px) and (max-width: 520px) {

  .logoSize {
    max-width: 8rem;
  }

  .logoSizeFixed {
    max-width: 7rem;
  }

  .label {
    display: block;
  }


  .infoLine {
    max-width: 65%;
  }

  .textBold {
    max-width: -webkit-fill-available;
  }

  .explanation {
    width: 85%;
  }

  .selectLanguageContainer {
    width: 25%;
  }

  .teacherPhoto {
    width: 5rem;
    height: 5rem;
  }


}