@import "../../styles/_variables";

.bodyLoading {
    display: flex;
    justify-content: center;
    height: 100vh;
    transition: background-color 1s ease;
    margin: 10rem auto;
    position: relative;
    overflow: hidden;
}

.backgroundPattern {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(45deg,
            rgba(0, 0, 0, 0.1) 0,
            rgba(0, 0, 0, 0.1) 1px,
            transparent 1px,
            transparent 5px);
    opacity: 0.2;
    pointer-events: none;
    animation: movePattern 10s linear infinite;
}

@keyframes movePattern {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100px 100px;
    }
}

.loadingContainer {
    text-align: center;
    width: 80%;
    max-width: 400px;
    position: relative;
    z-index: 1;
}

.loadingText {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: opacity 0.3s ease, color 1s ease;
}

.previousText,
.olderText,
.oldestText {
    position: absolute;
    width: 100%;
    left: 0;
}

.previousText {
    top: -20px;
    font-size: 18px;
    opacity: 0.4;
    transition: opacity 1s ease, transform 1s ease, font-size 1s ease;
}

.olderText {
    top: -55px;
    font-size: 15px;
    opacity: 0.3;
    transition: opacity 1s ease, transform 1s ease, font-size 1s ease;
}

.oldestText {
    top: -77px;
    font-size: 13px;
    opacity: 0.2;
    transition: opacity 1s ease, transform 1s ease, font-size 1s ease;
}

.progressBar {
    width: 100%;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
    height: 30px;
    position: relative;
}

.progressBarInner {
    height: 100%;
    width: 0;
    background-color: #4caf50;
    transition: width 0.3s ease;
    position: absolute;
}