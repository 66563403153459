.header {
  display: grid;
  justify-items: center;
  margin: 2rem 24px;
  grid-column-start: 2;
}

.headerLogo {
  max-width: 16rem;
  display: block;
}

.noHeader {
  display: none;
}

@media only screen and (max-width: 1096px) {
  .headerLogo {
    max-width: 15rem;
  }

}

@media only screen and (max-width: 760px) {
  .headerLogo {
    max-width: 14rem;
  }

}

@media only screen and (max-width: 560px) {
  .headerLogo {
    max-width: 13rem;
  }

}