@import "../../styles/_variables";

.table {
  display: block;
  height: 80vh;
  overflow-y: scroll;
  border-collapse: collapse;
  border-top: 1px solid rgb(200, 200, 200);
  text-align: center;
  width: 100%;
  border-right: none;
  border-left: none;
  border-bottom: none;
}

.table td,
th {
  padding: 5px 10px;
}

thead {
  background-color: white !important;
  height: 50px;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.loading {
  color: $cyan500;
  text-align: center;
  margin-top: 150px;
}

.createUserButton {
  padding: 0px 30px 0px 30px;
  font-size: 14px !important;
  line-height: 40px;
}

.editUserButton {
  color: $cyan500;
  cursor: pointer;
}

.deleteUserButton { 
  color: #e25858; 
  cursor: pointer;
}

.newPassword {
  color: $cyan500; 
  cursor: hand;
}

.arrowsDownUp {
  position: relative;
  top: 2.5px;
  color: $cyan500;
}