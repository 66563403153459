@import "../../styles/_variables";

@media only screen and (max-width: 600px) { 

    .minus{
        margin-top:10px;
        font-size:40px !important;
        color: $cyan500;
    }
    .plus{
        margin-top:10px;
        font-size:40px !important;
        color: $cyan500;
    }
}

@media only screen and (min-width: 601px) {
    .listControlContainer{
        display:flex
    }

    .minus{
        margin-top:5px;
        font-size:25px;
        margin-left:2px;
        margin-right:2px;
        color: $cyan500;
    }
    .plus{
        margin-top:5px;
        margin-left:2px;
        margin-right:2px;
        font-size:25px;
        color: $cyan500;
    }
}

.listControlContainer{
    display:flex
}