@import "../../styles/_variables";

.menuItems {
  vertical-align: middle;
  padding: 5px;
  width: 100%;
  cursor: pointer;
}

.menuItemsSmall {
  vertical-align: middle;
  width: 75px;
  text-align: center;
  display: grid;
  justify-items: center;
  padding-top: 5px;
  cursor: pointer;
}

.menuItemsSmall.menuItemsCyan900 {
  cursor: pointer;
}

.menuItemsCyan {
  background-color: $cyan500;
  cursor: pointer;
}

.menuItemsCyan900 {
  background-color: $cyan900;
  margin-top: 10px;
  cursor: default;
}

.menuItemsCyanCategory {
  background-color: $cyan600;
  cursor: pointer;
}

.menuItemsCyan900Clickable {
  background-color: $cyan900;
  margin-top: 10px;
  cursor: pointer;
}

.menuItemsCyan900Clickable:hover {
  background-color: $white;
  margin-top: 10px;
  cursor: pointer;

  >.iconWhite {
    filter: $cyan500SVG;
  }

  >.labelWhite {
    color: $cyan500;
  }
}

.menuItemsCyan:hover {
  background-color: white;

  >.iconWhite {
    filter: $cyan500SVG;
  }

  >.labelWhite {
    color: $cyan500;
  }
}

.menuItemsCyanCurrentPage {
  background-color: white;

  >.iconWhite {
    filter: $cyan500SVG;
  }

  >.labelWhite {
    color: $cyan500;
  }
}

.menuItemsCyan900CurrentPage {
  background-color: $white;
  margin-top: 10px;
  cursor: pointer;

  >.iconWhite {
    filter: $cyan500SVG;
  }

  >.labelWhite {
    color: $cyan500;
  }
}



.menuItemsOrange {
  background-color: white;

  >.iconOrange {
    filter: $orange100SVG;
    max-width: 20px !important;
  }

  >.labelOrange {
    color: $orange100;
  }
}

.menuItemsOrange:hover {
  background-color: $orange100;

  >.iconOrange {
    filter: $whiteSVG;
    max-width: 20px !important;
  }

  >.labelOrange {
    color: $white;
  }
}

.menuItemsOrangeCurrentPage {
  background-color: $orange100;

  >.iconOrange {
    filter: $whiteSVG;
    max-width: 20px !important;
  }

  >.labelOrange {
    color: $white;
  }
}

.menuItemsWhite {
  background-color: $white;
}

.menuItemsWhite:hover {
  background-color: $cyan500;

  >.iconCyan {
    filter: $whiteSVG;
  }

  >.labelCyan {
    color: white;
  }
}

.menuItemsWhiteCurrentPage {
  background-color: $cyan500;

  >.iconCyan {
    filter: $whiteSVG;
  }

  >.labelCyan {
    color: white;
  }
}

.icon {
  max-width: 24px;
  vertical-align: middle;
}

.iconSmall {
  max-width: 24px;
  vertical-align: middle;
}

.label {
  vertical-align: middle;
  padding-left: 5px;
  text-transform: capitalize;
}

.labelSmall {
  vertical-align: middle;
  text-transform: capitalize;
  font-size: 12px;
}

.iconWhite {
  filter: $whiteSVG;
}

.labelWhite {
  color: white;
}

.iconCyan {
  filter: $cyan500SVG;
}

.labelCyan {
  color: $cyan500;
}

.iconCaret {
  max-width: 18px;
  vertical-align: middle;
  margin-left: 5px;
}