 /* .stars {
  width: 85px;
  height: 24px;
}
.test {
  border: 1px solid red;
} */

 .ant-rate-star {
   font-size: 2.5rem;
   display: flex;
   justify-content: center;
 }

 .ant-rate-star.ant-rate-star-full {
   color: #B4B4B2;
   /* margin: -3.5px; */
 }

 .ant-rate-star.ant-rate-star-zero i svg {
   width: 150px;
   height: 200px;
   /* margin: -3.5px; */
 }

 .ant-rate-star.ant-rate-star-half.ant-rate-star-active i svg {
   width: 150px;
   height: 200px;
   color: #F3933F;
   /* margin: -3.5px; */
 }

 .ant-rate-star.ant-rate-star-full,
 .ant-rate-star.ant-rate-star-zero,
 .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
   transition: transform 0s;
   color: #F3933F;
 }

 .ant-rate-star.ant-rate-star-half.ant-rate-star-active:hover {
   transform: scale(0.91);
   color: #F3933F;
 }

 .ant-rate-star.ant-rate-star-full:hover {
   transform: scale(0.91);
   color: #F3933F;
 }

 .ant-rate-star.ant-rate-star-zero:hover {
   transform: scale(0.91);
 }