@import "../../styles/_variables";

.alignCheckboxItems {
    display: contents;
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 2.188rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
}

/* Hide deafult checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create Custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $white;
    border-radius: 0.25rem;
}

/* Set checkbox color (border and hover) */

.chkGray {
    border: solid $gray300;

    &:hover {
        border: solid $gray400;
    }
}

.chkCyan {
    border: solid $cyan500;

    &:hover {
        border: solid $cyan900;
    }
}

.chkOrange {
    border: solid $orange100;

    &:hover {
        border: solid $orange300;
    }
}


/* Create a hide check icon, when is unchecked */
.checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 0.375rem;
    top: 0.125rem;
    width: 0.313rem;
    height: 0.625rem;
    border: solid $cyan500;
    border-width: 0 0.188rem 0.188rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Show check icon when input is checked */
.checkbox input:checked~.checkmark:after {
    display: flex;
}